import React, { useMemo } from 'react'
import { Seo, Text } from '../types'
import { MainLayout } from '../components/layouts/Main'
import { SEO as SeoPage } from '../components/ui-components/Seo'
import { Poster } from '../components/ui-components/project/Poster'
import { Main } from '../components/ui-components/project/Main'
import { Pictures } from '../components/ui-components/project/Pictures'
import { Linked } from '../components/ui-components/project/Linked'
import { Image } from '../components/ui-components/project/Image'
import { PriceForm } from '../components/ui-components/forms'
import { getTextByKey } from '../utils'
import { useWindowSize } from '../hooks/useWindowSize'
import { WINDOW_MOBILE_WIDTH } from '../common/constants'
import '../components/ui-components/project/description.css'

const ProjectTemplate = (
    {
        pageContext: {
            project,
            texts,
            language,
        },
    }
) => {
    const [width] = useWindowSize()

    const seo: Seo = useMemo(() => ({
        title: project.title,
        description: project.meta?.description ?? '',
        keywords: project.meta?.keywords ?? '',
        lang: language
    }), [project, language])

    const urlPrefix = useMemo(() => (
        `projects/${project.url.url}`
    ), [])

    const linkedTexts = useMemo(() => ({
        button: texts.find((t: Text) => t.key === 'project-more-button').value
    }), [texts])

    const priceForm = useMemo(() => ({
        header: getTextByKey(texts, 'contacts-form-header'),
        name: getTextByKey(texts, 'contacts-name-placeholder'),
        phone: getTextByKey(texts, 'contacts-phone-placeholder'),
        email: getTextByKey(texts, 'contacts-email-placeholder'),
        button: getTextByKey(texts, 'contacts-button-more'),
        thanks: getTextByKey(texts, 'contacts-button-thanks')
    }), [getTextByKey, texts])

    const isMobile = useMemo(() => width < WINDOW_MOBILE_WIDTH, [width])

    return (
        <MainLayout
            urlPrefix={urlPrefix}
            language={language}
        >
            <SeoPage {...seo}/>

            <section className='project'>
                <Poster
                    title={project.title}
                    image={project.poster}
                />

                <Main
                    project={project}
                    texts={texts}
                />

                {isMobile && (
                    <div className='project__pictures'>
                        <Image picture={project.descriptionPicture.picture}/>
                    </div>
                )}

                {isMobile && (
                    <div
                        className='project__description'
                        dangerouslySetInnerHTML={{ __html: project.details }}
                    />
                )}

                <Pictures
                    pictures={project.pictures}
                />


                {!isMobile && (
                    <div
                        className='project__description container'
                        dangerouslySetInnerHTML={{ __html: project.details }}
                    />
                )}
            </section>

            <Linked texts={linkedTexts}/>

            <PriceForm form={priceForm}/>
        </MainLayout>
    )
}

export default ProjectTemplate