import React from 'react'
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon } from '../../../icons'
import 'react-awesome-slider/dist/styles.css'

interface FullScreenPictureProps {
    url: string | null
    onClickLeft: VoidFunction
    onClickRight: VoidFunction
    onClose: (event) => void
}

export const FullScreenPicture = (
    {
        url,
        onClickLeft,
        onClickRight,
        onClose,
    }: FullScreenPictureProps,
) => {
    return (
        <div className='project__picture-fullscreen'>
            <div
                className='fullscreen__close'
                onClick={onClose}
            >
                <CloseIcon/>
            </div>

            <div
                className='fullscreen__arrow fullscreen__arrow-left'
                onClick={onClickLeft}
            >
                <ArrowLeftIcon/>
            </div>

            <div className='fullscreen__image'>
                <img
                    src={`https://n-design.studio${url}`}
                    loading='lazy'
                />
            </div>

            <div
                className='fullscreen__arrow fullscreen__arrow-right'
                onClick={onClickRight}
            >
                <ArrowRightIcon/>
            </div>
        </div>
    )
}