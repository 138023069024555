import React, { useMemo } from 'react'
import { Text } from '../../../types';

const TEXT_PREFIX = 'project-info-'

export const Main = (
    {
        project,
        texts: mainTexts,
    },
) => {
    const texts = useMemo(() => ({
        area: mainTexts.find((text: Text) => text.key === `${TEXT_PREFIX}area-placeholder`).value,
        date: mainTexts.find((text: Text) => text.key === `${TEXT_PREFIX}year-placeholder`).value,
        city: mainTexts.find((text: Text) => text.key === `${TEXT_PREFIX}city-placeholder`).value,
        team: project.team
            ? mainTexts.find((text: Text) => text.key === `${TEXT_PREFIX}team-placeholder`).value
            : undefined,
    }), [mainTexts, project]);

    const dateYear = useMemo(() => new Date(project.date).getFullYear(), [project.date])

    return (
        <div className='project__info container'>
            <div className='project__info-text' dangerouslySetInnerHTML={{ __html: project.description }}></div>
            <div className='project__info-characteristics'>
                <div className='table'>
                    <div className='row'>
                        <div className='column'>{texts.area}</div>
                        <div className='column'>{project.area} м²</div>
                    </div>
                    <div className='row'>
                        <div className='column'>{texts.date}</div>
                        <div className='column'>{dateYear}</div>
                    </div>
                    <div className='row'>
                        <div className='column'>{texts.city}</div>
                        <div className='column'>{project.city}</div>
                    </div>
                    {project.team && (
                        <div className='row'>
                            <div className='column'>{texts.team}</div>
                            <div className='column'>{project.team}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}