import { useState, useEffect } from 'react'
import { WINDOW_MOBILE_WIDTH } from '../common/constants'

export const useWindowSize = () => {
    const [ width, setWidth ] = useState(WINDOW_MOBILE_WIDTH - 1)
    const [ height, setHeight ] = useState(WINDOW_MOBILE_WIDTH - 1)

    useEffect(() => {
        setWidth(window.outerWidth)
        setHeight(window.outerHeight)
    }, [])

    return [ width, height ]
}