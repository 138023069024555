import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AwesomeSlider from 'react-awesome-slider'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { WINDOW_MOBILE_WIDTH } from '../../../common/constants'
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon } from '../../../icons'
import { useOnKeyPress } from '../../../hooks/useOnKeyPress'
import { Image } from './Image'
import 'react-awesome-slider/dist/styles.css'
import { FullScreenPicture } from './FullScreenPicture'

interface Picture {
    id: string
    picture: {
        publicUrl: string
    }
}

interface PicturesProps {
    pictures: Picture[]
}

export const Pictures = ({ pictures }: PicturesProps) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const sliderRef = useRef<HTMLDivElement | null>(null)

    const [selected, setSelected] = useState<number>(0)
    const [renderFullScreen, setRenderFullScreen] = useState(false)
    const [selectedPicture, setSelectedPicture] = useState<Picture | null>(null)

    const arrowLeftPress = useOnKeyPress('ArrowLeft');
    const arrowRightPress = useOnKeyPress('ArrowRight');

    const [width] = useWindowSize()

    const handleItemClick = useCallback((index: number) => () => {
        setSelected(index)
    }, [])

    const lastPictureIndex = useMemo(() => pictures.length - 1, [pictures]);

    const handleClickLeft = useCallback(() => {
        const index = selected === 0 ? lastPictureIndex : selected - 1
        const picture = pictures[index]

        setSelected(index)
        setSelectedPicture(picture)
    }, [selected, setSelected, setSelectedPicture, pictures, lastPictureIndex])

    const handleClickRight = useCallback(() => {
        const index = selected === lastPictureIndex ? 0 : selected + 1
        const picture = pictures[index]

        setSelected(index)
        setSelectedPicture(picture)
    }, [selected, setSelected, setSelectedPicture, pictures, lastPictureIndex])

    const handleOpenImage = useCallback((picture: Picture) => () => {
        setSelectedPicture(picture)
        setRenderFullScreen(true)
    }, [setSelectedPicture, setRenderFullScreen])

    const handleCloseImage = useCallback((event) => {
        event.preventDefault()

        ref.current?.scrollTo({ behavior: 'instant' })

        setRenderFullScreen(false)
        setSelectedPicture(null)
    }, [setSelectedPicture, setRenderFullScreen])

    useEffect(() => {
        setTimeout(() => {
            handleClickLeft()
        }, 200)
    }, [arrowLeftPress])

    useEffect(() => {
        setTimeout(() => {
            handleClickRight()
        }, 200)
    }, [arrowRightPress])

    const renderArrowLeft = () => (
        <div
            className='project__slider-arrow'
            onClick={handleClickLeft}
        >
            <ArrowLeftIcon/>
        </div>
    )

    const renderArrowRight = () => (
        <div
            className='project__slider-arrow'
            onClick={handleClickRight}
        >
            <ArrowRightIcon/>
        </div>
    )

    if (width < WINDOW_MOBILE_WIDTH) {
        return (
            <div className='project__pictures'>
                {pictures.map(({id, picture}) => <Image key={id} picture={picture}/>)}
            </div>
        )
    }

    if (renderFullScreen && selectedPicture) {
        return (
            <FullScreenPicture
                url={selectedPicture.picture.publicUrl}
                onClickLeft={handleClickLeft}
                onClickRight={handleClickRight}
                onClose={handleCloseImage}
            />
        )
    }

    return (
        <div
            ref={ref}
            className='project__pictures project__slider-container'
        >
            <div id='project__slider' className='project__slider'>
                <AwesomeSlider
                    organicArrows={false}
                    buttonContentLeft={renderArrowLeft()}
                    buttonContentRight={renderArrowRight()}
                    bullets={false}
                    selected={selected}
                >
                    {pictures.map(({ id, picture }) => (
                        <div
                            key={id}
                            className='project__slider-item'
                            onClick={handleOpenImage({id, picture})}
                        >
                            <img
                                src={`https://n-design.studio${picture.publicUrl}`}
                                loading='lazy'
                            />
                        </div>
                    ))}
                </AwesomeSlider>
            </div>

            <div
                className='project__slider-navigation'
                style={{
                    height: `${document.getElementById('project__slider')?.getElementsByClassName('awssld__container')[0].clientHeight}px`
                }}
            >
                {pictures.map(({ id, picture: {publicUrl} }, index: number) => (
                    <div
                        key={`${id}-${index}`}
                        onClick={handleItemClick(index)}
                    >
                        <img
                            src={`https://n-design.studio${publicUrl}`}
                            loading='lazy'
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}