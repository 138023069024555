import React, { useEffect, useState, useMemo } from 'react'
import { Poster as IPoster } from '../../../types/project'

interface PosterProps {
    image: IPoster;
    title: string;
}

export const Poster = (
    { image, title }: PosterProps,
) => {
    const [ windowWidth, setWindowWidth ] = useState(727)

    useEffect(() => {
        setWindowWidth(window.outerWidth)
    }, [])

    const backgroundImage = useMemo(() => (
        `url('https://n-design.studio${(windowWidth > 728) ? image.desktop.publicUrl : image.mobile.publicUrl }')`
    ), [windowWidth, image])

    return (
        <div
            className='project__poster'
            style={{ backgroundImage }}
        >
            <h2 className='project__poster-title'>{title}</h2>
        </div>
    )
}