import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { uriFixer} from '../../../utils'
import 'animate.css'

export const Image = ({ picture }) => {
    const [ imageLoad, setImageLoad ] = useState(false)
    const { ref, inView } = useInView({
        threshold: 0.1
    })

    useEffect(() => {
        if (!imageLoad && inView) {
            setImageLoad(true)
        }
    }, [ inView ])

    return (
        <img
            ref={ref}
            src={`https://n-design.studio${picture.publicUrl}`}
            className={`project__picture animate__animated ${imageLoad ? 'animate__fadeInUp' : ''}`}
            loading='lazy'
            style={{ opacity: 0 }}
        />
    )
}
