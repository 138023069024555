import { RefObject, useEffect, useState } from 'react'

export const useOnKeyPress = (targetKey: string) => {
    const [keyPressed, setKeyPressed] = useState(false);

    const handleKeyDown = ({ key }: { key: string }) => {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }

    const handleKeyUp = ({ key }: { key: string }) => {
        if (key === targetKey) {
            setKeyPressed(false)
        }
    }

    useEffect(() => {
        window?.addEventListener('keydown', handleKeyDown)
        window?.addEventListener('keyup', handleKeyUp)

        return () => {
            window?.removeEventListener('keydown', handleKeyDown)
            window?.removeEventListener('keyup', handleKeyUp)
        }
    })

    return keyPressed
}