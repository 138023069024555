import React from 'react'

import './linked.css'

export const Linked = ({ texts }) => (
    <section className="more">
        <div className="more__button">
            <a href={`/projects`}>
                <button className="btn btn-primary">{texts.button}</button>
            </a>
        </div>
    </section>
)